import React from "react";
import PropTypes from "prop-types";

import { AnimatedRandomX } from "../Animations";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

const JobContainer = (props: any) => (
  <div className="job-opening">
    <div className="container-job-opening">
      <RandomBlockX className="div-block-7">
        <br />
        <br />‍
      </RandomBlockX>
      <RandomBlockX className="div-block-11" />
      <div className="div-block-85">
        <p className="p2-copy full">{props.jobInfo}</p>
      </div>
      <div className="div-block-84">
        <div className="cap-symbol-2">{"// "}</div>
        <div className="cap2">{props.jobArea}</div>
        <h1 className="heading full">{props.jobRole}</h1>
        <p className="p2 large-2">{props.jobDescription}</p>
      </div>
      <div className="background-content-5" />
    </div>
  </div>
);

export default JobContainer;

JobContainer.propTypes = {
  jobInfo: PropTypes.string,
  jobHours: PropTypes.string,
  jobArea: PropTypes.string,
  jobRole: PropTypes.string,
  jobDescription: PropTypes.string,
};
