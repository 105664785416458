import React from "react";
import { graphql } from "gatsby";
import JobContainer from "../components/JobContainer";
import BulletPointDescription from "../components/BulletPointDescription";
import ApplicationForm from "../components/ApplicationForm";
import SEO from "../components/SEO";
import { AnimatedRandomX } from "../components/Animations";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

const Job = (props: any) => {
  const jobOpening = props.data.prismicJob || {};
  return (
    <>
      <SEO {...props.data.prismicJob.data} />
      <div className="gradient">
        <JobContainer
          jobInfo={jobOpening.data.location}
          jobArea={jobOpening.data.job_area}
          jobRole={jobOpening.data.title}
          jobDescription={jobOpening.data.job_description}
        />
        <div className="job-opening-details">
          <div className="container-job-opening-details">
            {jobOpening.data.tasks ? (
              <BulletPointDescription
                title="The responsibilities"
                subtitle="Tasks to do:"
                bulletPoint={jobOpening.data.tasks}
              />
            ) : null}
            <div className="div-block-86" />
            <BulletPointDescription
              title="OUR IMPOSSIBLE MATCH"
              subtitle="Ideal Profile:"
              bulletPoint={jobOpening.data.profile}
            />
            <BulletPointDescription
              title="WHY WE ARE AMAZING"
              subtitle="Things to expect:"
              bulletPoint={jobOpening.data.expectations}
            />
          </div>
        </div>
        <div className="application2">
          <div className="div-block-118">
            <div className="application-text" />
          </div>
          <ApplicationForm
            dark
            applicationType="form2"
            jobTitle={jobOpening.data.title}
            jobInfo={jobOpening.data.location}
          />
          <RandomBlockX className="div-block-87" />
          <RandomBlockX className="div-block-12" />
          <RandomBlockX className="div-block-22">
            <br />
            <br />‍
          </RandomBlockX>
        </div>
      </div>
    </>
  );
};

export default Job;

export const pageQuery = graphql`
  query JobQuery($uid: String!) {
    prismicJob(uid: { eq: $uid }) {
      uid
      data {
        expectations {
          expectations_description
        }
        title
        description
        keywords
        image {
          url
        }
        canonical_url {
          url
        }
        job_area
        job_description
        location
        profile {
          profile_description
        }
        tasks {
          task_description
        }
      }
    }
  }
`;
