import React from "react";
import PropTypes from "prop-types";

const BulletPointDescription = (props: any) => (
  <div className={`wrapper-${props.wrapperType || "details"}`}>
    {props.title ? (
      <div>
        <div className="cap-symbol">{"// "}</div>
        <div className="cap">{props.title}</div>
      </div>
    ) : null}
    {props.subtitle ? <h1 className="heading">{props.subtitle}</h1> : null}

    <ul>
      {props.bulletPoint
        ? props.bulletPoint.map((item, index) => (
            <li className="list-item" key={`list-item-${index}`}>
              {item.profile_description ||
                item.task_description ||
                item.expectations_description ||
                ""}
            </li>
          ))
        : null}
    </ul>
  </div>
);

export default BulletPointDescription;

BulletPointDescription.propTypes = {
  wrapperType: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bulletPoint: PropTypes.array,
};
